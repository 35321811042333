import React from 'react';

import { bindActionCreators } from '@reduxjs/toolkit';
// Redux
import { connect } from 'react-redux';

import { formatDate } from '../../../../../hooks/useTimer';

import { updateCourseDetails } from '../../../../../redux/slices/courseConsumption';

import WatchIcon from '../../../../../assets/images/course-dashboard/icons/WatchedIcon.svg';

import { countDurationCourseConsumption, pluralVal } from '../../../../../utils/helper';

import ActionButton from '../ActionButton';
import styles from './InfoBar.module.scss';

function InfoBarComponent({
  resource,
  resourceType,
  resourceData,
  hideInfoBar,
  handleClick,
}) {
  const infoComponents = [];
  if (resourceType === 'VIDEO') {
    if (resource?.isVideoWatched === 1) {
      infoComponents.push(
        <div className={styles.watchOuterBox}>
          <WatchIcon />
          <span className={styles.watchVideoText}>Watched</span>
        </div>,
      );
    } else if (resource.videoTotalMins) {
      infoComponents.push(
        <span>
          {/* eslint-disable-next-line max-len */}
          {countDurationCourseConsumption(
            (resource.videoTotalMins - resource.videoWatchedMins) / 1000,
            true,
            true,
          )}{' '}
          left
        </span>,
      );
    }
  }

  if (resourceType === 'TEST_SERIES') {
    switch (resource.testStatus) {
      case 'COMING_SOON':
        if (resource.comingSoon) {
          infoComponents.push(<span className={styles.courseDuration}>{formatDate(resource?.comingSoon)}</span>);
        }
        break;
      default:
        infoComponents.push(
          <span className={styles.courseDuration}>
            {pluralVal(resource.numberOfQuestions, 'Que', 'Ques')} • {pluralVal(resource.duration / 60, 'Min', 'Mins')}
          </span>,
        );
    }
  }

  if (resource?.isNewlyAdded) {
    infoComponents.push(
      <span
        className={`${styles.isNewItem} ${
          (resourceData?.isBookMark || resource?.feedType === 'QUIZ') && styles.isBookmarkNew
        }`}
      >
        New
      </span>,
    );
  }

  if (infoComponents.length === 0 && !resourceData?.isBookMark) {
    infoComponents.push(
      <span
        style={{
          color: 'transparent',
          userSelect: 'none',
          pointerEvents: 'none',
        }}
      >
        Placeholder
      </span>,
    );
  }

  return (
    <div className={styles.infoBar}>
      <div className={styles.infoItemsWrapper}>{infoComponents}</div>
      {hideInfoBar !== true && (
        <ActionButton
          handleClick={handleClick}
          resource={resourceData?.isBookMark ? resourceData : resource}
          resourceType={resourceType}
          data-testid="action-button"
        />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(
    {
      updateCourseDetails,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(InfoBarComponent);
