import React from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import styles from './LiveClassNotification.module.scss';
import { markNotificationRead } from '../../../../../redux/slices/notificationSlice';

moment.locale('en-min', {
  parentLocale: 'en',
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'just now',
    ss: '%d s',
    m: '%d min',
    mm: '%d mins',
    h: '%d h',
    hh: '%d hrs',
    d: '%d d',
    dd: '%d d',
    w: '%d w',
    ww: '%d w',
    M: '%d mo',
    MM: '%d mo',
    y: '%d Y',
    yy: '%d Y',
  },
});

function LiveClassNotification({ detail }) {
  const dispatch = useDispatch();

  const notificationLink = detail?.metaData?.joining_link ?? detail?.metaData?.deeplink ?? '';

  const handleNotificationClick = () => {
    dispatch(markNotificationRead(detail.notificationId))
      .finally(() => {
        if (notificationLink.length) {
          window.location.href = notificationLink;
        }
      });
  };

  return (
    <div
      className={classNames(styles.notificationWrapper, {
        [styles.isNew]: !detail?.isRead,
        [styles.isLink]: notificationLink.length,
      })}
      onClick={handleNotificationClick}
      data-testid="notification"
    >
      <div className={styles.thumbnailWrapper}>
        <div className={styles.thumbnail}>
          <Image
            src={detail.metaData.course_image}
            alt={detail.metaData.title}
            fill
          />
        </div>
      </div>
      <div className={styles.notificationContent}>
        <h4 className={styles.notificationTitle}>{detail.metaData.title}</h4>
        <span className={styles.notificationCourse}>
          Course: <span>{detail.metaData.course_name}</span>
        </span>
        {detail?.metaData?.author && (
          <span className={styles.notificationAuthor}>By: {detail.metaData.author}</span>
        )}
      </div>
      {detail.createdAt && (
        <span className={styles.time}>
          {moment.unix(detail.createdAt).locale('en-min').fromNow(true)}
        </span>
      )}

      <span className={styles.readIndicator} />
    </div>
  );
}

export default LiveClassNotification;
