import React from 'react';

import { faCheck, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from './QuestionStateIcon.module.scss';

function QuestionStateIcon({
   type, size = 'md', countWithLabel = false, withLabel = false,
   isActive = false, onClick, children,
}) {
  const stateIcon = React.useMemo(() => {
    if (children && !withLabel) return children;

    switch (type) {
      case 'answered':
        return faCheck;
      case 'not-answered':
        return faTimes;
      case 'marked-for-review':
        return faStar;
      case 'marked-for-review-and-answered':
        return faCheck;
      case 'not-visited':
      case 'wrong-answered':
        return faTimes;
      default:
        return children;
    }
  }, [type, children, withLabel]);

  const stateLabel = React.useMemo(() => {
    if (!withLabel) return children;

    switch (type) {
      case 'answered':
        return 'Answered';
      case 'not-answered':
        return 'Not Answered';
      case 'marked-for-review':
        return 'Marked for Review';
      case 'marked-for-review-and-answered':
        return 'Marked for Review and Answered';
      case 'wrong-answered':
        return 'Wrong Answered';
      case 'not-visited':
        return 'Not Visited';
      default:
        return children;
    }
  }, [type, withLabel, children]);

  return (
    <div
      data-testid="question-state-icon-section-wrapper"
      className={classNames(styles.wrapper, {
        [styles.active]: isActive,
        [styles.smallSize]: size === 'sm',
        [styles.largeSize]: size === 'lg',
        [styles.answered]: type === 'answered',
        [styles.notVisited]: type === 'not-visited',
        [styles.notAnswered]: type === 'not-answered',
        [styles.wrongAnswered]: type === 'wrong-answered',
        [styles.markedForReview]: type === 'marked-for-review',
        [styles.markedForReviewAndAnswered]: type === 'marked-for-review-and-answered',
      })}
    >
      <span className={styles.iconWrapper} onClick={onClick} data-testid="question-state-icon-button-state">
        {typeof stateIcon?.prefix !== 'undefined' ? <FontAwesomeIcon icon={stateIcon} /> : children}
      </span>

      {withLabel && stateLabel && (
        <span>
          {countWithLabel && `${children} `}
          {stateLabel}
        </span>
      )}
    </div>
  );
}

export default QuestionStateIcon;
