import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePause, faCirclePlay } from '@fortawesome/free-regular-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import { resumeTest } from '../../../redux/slices/testSeriesSlice';

import PauseModal from '../../../container/TestSeries/PauseModal';

import useTimer from '../../../hooks/useTimer';

import styles from './TimeTracker.module.scss';

function TimeTracker({ question, currentSection, onTimeEnd = () => null }) {
  const dispatch = useDispatch();

  const [showPause, setShowPause] = React.useState(false);

  const { isPaused, isFixedMock, isSectionalTiming, testDuration, testState } = useSelector((state) => ({
    isPaused: state.testSeries.isPaused,
    isFixedMock: state.testSeries.isFixedMock,
    isSectionalTiming: state.testSeries.isSectionalTiming,
    testDuration: state.testSeries.testDuration,
    testState: state.testSeries.testState.detail ?? [],
  }));

  // eslint-disable-next-line no-shadow
  const timeSpent = React.useMemo(() => testState.reduce((prev, question, index) => {
    if (!question) return parseInt(prev, 10);
    if (
      isSectionalTiming &&
      (index < currentSection.startQuestion - 1 || index > currentSection.endQuestion)
    ) return parseInt(prev, 10);

    return Math.floor(parseInt(prev, 10) + (parseInt(question.timeTaken, 10) / 1000));
  }, 0), [testState, currentSection, isSectionalTiming]);

  const { seconds, minutes, hours, pause, resume, start, setExpiryTimestamp } = useTimer({
    startTimestamp: moment().toDate(),
    expiryTimestamp: moment()
      // eslint-disable-next-line no-unsafe-optional-chaining
      .add((isSectionalTiming ? currentSection?.secTime : testDuration) - timeSpent, 'seconds')
      .toDate(),
    onTimeExpire: onTimeEnd,
    autoStart: false,
  });

  React.useEffect(() => {
    if (!isSectionalTiming && testDuration) {
      setExpiryTimestamp(moment().add(testDuration - timeSpent, 'seconds').toDate());
      start();
    } else if (isSectionalTiming) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setExpiryTimestamp(moment().add(currentSection?.secTime - timeSpent, 'seconds').toDate());
      start();
    }

    return () => pause();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSectionalTiming, question, testDuration, setExpiryTimestamp]);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isPaused ? pause() : resume();
  }, [isPaused, pause, resume]);

  const handleTimeToggle = () => {
    // eslint-disable-next-line no-unused-expressions
    isPaused ? dispatch(resumeTest()) : setShowPause((prev) => !prev);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <button
          type="button"
          className={styles.actionBtn}
          onClick={handleTimeToggle}
          disabled={isFixedMock || true}
          data-testid="time-tracker-button-toggle"
        >
          <FontAwesomeIcon icon={isPaused ? faCirclePlay : faCirclePause} />
        </button>

        <span className={styles.currentTime}>
          {String(hours).padStart(2, '0')}h:
          {String(minutes).padStart(2, '0')}m:
          {String(seconds).padStart(2, '0')}s
        </span>

        {(Number(question?.pos ?? 0) || Number(question?.neg ?? 0)) === true && (
          <div className={styles.actionsWrapper}>
            <button
              type="button"
              className={classNames(styles.actionBtn, styles.increase)}
              data-testid="time-tracker-button-increase"
            >
              <span>+{Number(question?.pos ?? 0).toFixed(1)}</span>
            </button>

            <button
              type="button"
              className={classNames(styles.actionBtn, styles.decrease)}
              data-testid="time-tracker-button-decrease"
            >
              <span>-{Number(question?.neg ?? 0).toFixed(1)}</span>
            </button>
          </div>
        )}
      </div>

      <PauseModal show={showPause} setShow={setShowPause} />
    </>
  );
}

export function TimeTrackerSolution({ question }) {
  if (!(Number(question?.pos ?? 0) || Number(question?.neg ?? 0))) return null;

  return (
    <div className={classNames(styles.wrapper, styles.isSolution)}>
      <div className={styles.actionsWrapper} style={{ margin: '0 auto' }}>
        <button
          type="button"
          className={classNames(styles.actionBtn, styles.increase)}
          data-testid="time-tracker-solution-button-increase"
        >
          <span>+{Number(question?.pos ?? 0).toFixed(1)}</span>
        </button>

        <button
          type="button"
          className={classNames(styles.actionBtn, styles.decrease)}
          data-testid="time-tracker-solution-button-decrease"
        >
          <span>-{Number(question?.neg ?? 0).toFixed(1)}</span>
        </button>
      </div>
    </div>
  );
}

export default TimeTracker;
