import React from 'react';

import styles from './InfoStrip.module.scss';

function InfoStripComponent({ children }) {
  return (
    <div className={styles.wrapper} data-testid="info-strip-wrapper">
      <span>{children}</span>
    </div>
  );
}

export default InfoStripComponent;
