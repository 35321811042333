/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';

import { IMAGE_PREFIX } from '../../../constants/api';
import { javaApi } from '../../../utils/AxiosInstance';

import styles from '../../../styles/Home.module.scss';

// import SwiperTestimonials from './SwiperTestimonials';
const SwiperTestimonials = dynamic(() => import('./SwiperTestimonials'));
function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [testimonials, setTestimonials] = useState({});
  const getHomeTestimonials = async () => {
    try {
      const res = await javaApi.get('common-ws/api/v1/web/home/testimonials/list');
      if (res.status === 200) {
        const { data } = res.data;
        setTestimonials(data);
        setTestimonialsData(data[1]);
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    getHomeTestimonials();
  }, []);
  function SwiperSlider() {
    return (
      <div className={styles.right} data-testid="testimonials-slider">
        <div className={styles.profilepicMain}>
          <SwiperTestimonials testimonialsData={testimonialsData} testimonials={testimonials} />
        </div>
      </div>
    );
  }
  return (
    <div data-testid="testimonials-section" className={styles.container}>
      {testimonialsData && (
        <div className={styles.row7}>
          <div className={styles.left}>
            <h2 className={styles.heading} data-testid="testimonials-heading">
              <Image src={`${IMAGE_PREFIX}/Line_P.png`} alt="Testimonials" height={10} width={50} />
              TESTIMONIALS
            </h2>
            <div className={styles.testimonialsData}>
              <p className={styles.p1}>We Value Our Students</p>
              <p className={styles.p3}>Let’s Hear From Them</p>
              <p className={styles.p2} data-testid="testimonials-message">
                <span className={styles.study}>
                  Study
                  <span className={styles.iq}>IQ </span>
                </span>
                has got more than 100k positive ratings
                <br /> from their students.
              </p>
              <p className={styles.p4}>Some of the students were greatly helped.</p>
            </div>
          </div>
          <SwiperSlider />
        </div>
      )}
    </div>
  );
}

export default Testimonials;
