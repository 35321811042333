/* eslint-disable react/button-has-type */
import React from 'react';

import { useRouter } from 'next/router';

import FMStudentsBanner from '../../../assets/images/fixed-mock/fixmockbanner.svg';

import { formatDate } from '../../../utils/helper';

import InfoStripComponent from '../../InfoStrip';
import RippleButton from './RippleButton';
import styles from './index.module.scss';

function FixedMockCard({ item }) {
  const router = useRouter();

  const handlePushToFixMock = () => {
    router.push(`fixed-mock/${item?.slug}`);
  };

  return (
    <div className={styles.box}>
      <InfoStripComponent />
      <div className={styles.bannerimage}>
        <div className={styles.imagewrapper}>
          <FMStudentsBanner height={132} width={146} />
        </div>
        <div className={styles.clockSectionColumn}>
          <div className={`${styles.timewrapper} ${styles.titleWrapper}`}>
            <span className={styles.title}>{item?.title}</span>
            <span className={`${styles.cursetype}`}>{`(${item?.exam})`}</span>
          </div>
        </div>
        <div className={styles.clockSectionColumn}>
          <div className={`${styles.timewrapper} ${styles.startAtwrapper}`}>
            <span className={styles.start}> Starts at</span>
            <span className={styles.time}>{formatDate(item?.startTime, 'hh:mm aaa,')}</span>
            <span className={styles.time}>{formatDate(item?.startTime, 'ddd MMM')}</span>
          </div>
        </div>
        <div className={`${styles.clockSectionColumn} ${styles.knowMoreWrapper}`}>
          <RippleButton
            onClick={handlePushToFixMock}
            className={styles.btn_primary}
            rippleColor="#fff"
            opacity={0.38}
          >
            Know More
          </RippleButton>
        </div>
      </div>
    </div>
  );
}

export default FixedMockCard;
