/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { toast } from 'sonner';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useMoengageEventTracker } from '../../../../hooks/useMoengage';

import InfoBarComponent from './InfoBar';
import { isAndroidModal } from '../../../../redux/slices/courseConsumption';
import { saveUserPdfStatus } from '../../../../redux/slices/videodashboardSlice';

import NotepadIcon from '../../../../assets/images/course-dashboard/icons/notepad.svg';
import PDFIcon from '../../../../assets/images/course-dashboard/icons/pdf.svg';
import VideoIcon from '../../../../assets/images/course-dashboard/icons/video.svg';

// eslint-disable-next-line import/no-unresolved
import { javaApi } from '../../../../utils/AxiosInstance';
import { convertToSlug, formatElapsedTime, getDeviceTypeAndroid } from '../../../../utils/helper';

import NoVideo from '../../NoVIdeoModal/NoVideoModal';

import styles from './CourseItemResource.module.scss';

function ResourceIcon({ resourceType, ...rest }) {
  let Icon = VideoIcon;
  if (resourceType === 'TEXT') {
    Icon = PDFIcon;
  } else if (resourceType === 'PDF') {
    Icon = PDFIcon;
  } else if (resourceType === 'QUIZ') {
    Icon = NotepadIcon;
  } else if (resourceType === 'TEST_SERIES') {
    Icon = NotepadIcon;
  }

  return <Icon {...rest} />;
}

export const ResourceComponent = React.forwardRef(({ item, onClick, href = '',
  videoDash, resourceData, isBookMark, hideInfoBar, handleClick }, ref) => (
    <Link
      href={href}
      onClick={onClick}
      ref={ref}
      className={styles.anchorTag}
      data-testid="course-item-link-resource"
    >
      <div
        className={classNames(styles.wrapper, {
          [styles.videoDash]: videoDash === 'videoDash',
          [styles.feedTypeWrapper]: item?.feedType === 'QUIZ',
          [styles.bookMarkWrapper]: isBookMark,
        })}
        id="firstCourseItem"
      >
        {item?.feedType === 'QUIZ' && (
          <div className={`${styles.courseInfo} ${styles.feedInfo}`}>
            <h6 className={styles.feedTimeStamp}>{formatElapsedTime(item?.updatedAt)} ago</h6>
            <ResourceIcon
              resourceType={item?.contentType}
              className={`${styles.courseIcon} ${styles.feedCourseIcon}`}
              id={`${item?.contentType}_icon`}
            />
          </div>
        )}
        <div className={`${styles.courseInfo} ${isBookMark && styles.bookMarkInfo}`}>
          <h6 className={`${styles.courseTitle} ${item?.feedType === 'QUIZ' && styles.feedTitle}`}>
            {item?.name || item?.lessonName || item?.title || item?.videoName}
          </h6>
          {item?.feedType !== 'QUIZ' && (
            <ResourceIcon
              resourceType={item?.contentType}
              className={styles.courseIcon}
              id={`${item?.contentType}_icon`}
            />
          )}
        </div>
        {isBookMark && item?.contentType === 'VIDEO' && (
          <div className={styles.courseNameWrapper}>
            <span className={styles.bookmarkCourseName}>
              Course: <span className={styles.bookmarkCourseTitle}>{resourceData?.courseTitle}</span>
            </span>
          </div>
        )}
        <div
          className={
            (item?.feedType === 'QUIZ' && styles.freeQuizFooter) ||
            (isBookMark && styles.bookMarkFooter) ||
            styles.courseFooter
          }
        >
          <InfoBarComponent
            resource={item}
            handleClick={handleClick}
            resourceType={item?.contentType}
            resourceData={resourceData}
            hideInfoBar={hideInfoBar}
          />
        </div>
      </div>
    </Link>
  ));

ResourceComponent.displayName = 'ResourceComponent';

function CourseItemResourceComponent({
  courseId,
  item,
  skeleton,
  parents,
  videoDash,
  lessonposition,
  updatesTap,
  topicCount,
  setTopicCount,
  isUpdateLanguage,
  blockContentKey,
  setIsUpdateLanguage,
  resourceData,
  isBookMark,
  hideInfoBar = false,
}) {
  const router = useRouter();
  const [isModal, setIsModal] = React.useState(false);
  const dispatch = useDispatch();
  const testWindowRef = React.useRef();
  const startTestRef = React.useRef();
  const courseDetails = useSelector((state) => state.courseConsumption.courseDetails.details);
  const resourceDetails = useSelector((state) => state.resourceDashboard.resource.detail);
  const resourceType = item?.contentType;
  const resource = item;
  const [isAndroid, setIsAndroid] = React.useState(false);
  const trackMoengageEvent = useMoengageEventTracker();
  const [isSpinLoading, setIsSpinLoading] = React.useState(false);

  const lastFolder = [...(router?.query?.folders || [])]?.pop() || null;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const { userAgent } = window.navigator;
      setIsAndroid(getDeviceTypeAndroid(userAgent));
    }
    if (updatesTap === 'myPlaylist') {
      sessionStorage?.setItem('backButtonRoute', 'myPlaylist');
    } else {
      sessionStorage?.setItem('backButtonRoute', 'course-dashboard');
    }
  }, []);

  const testRef = (ref) => {
    const interval = window.setInterval(() => {
      if (ref?.current?.closed) {
        window.clearInterval(interval);
        window.location.reload();
      }
    }, 100);
  };
  React.useEffect(() => {
    testRef(startTestRef);
  }, [startTestRef]);
  React.useEffect(() => {
    testRef(testWindowRef);
  }, [testWindowRef]);

  if (skeleton) {
    return (
      <div className={styles.skeleton} data-testid="course-item-resource-skeleton">
        <div className={styles.header}>
          <span className={styles.folderIndex} />
          <span className={styles.folderTopics} />
        </div>
        <div className={styles.content}>
          <span className={styles.folderTitle} />
        </div>
        <div className={styles.footer} />
      </div>
    );
  }

  const currentRoute = router.asPath.replace(/\?.*/, '').match(/course-content\/\d+-\d+\/(.+)/);
  function findMatchingIndex(dataList, dataToMatch) {
    for (let i = 0; i < dataList?.length; i += 1) {
      const obj = dataList[i];
      if (obj?.assignedLessonId === dataToMatch?.assignedLessonId) {
        return i;
      }
    }
    return -1;
  }

  const handleClick = (val) => {
    if (val.preventDefault) val.preventDefault();

    if (isAndroid) {
      dispatch(isAndroidModal({ show: true }));
    } else {
      const { name, contentId, testId, id } = resource;
      sessionStorage?.setItem('sourceURL', 'course_dashboard');
      const topicPosition = findMatchingIndex(courseDetails?.data, resourceDetails) + 1;
      if (courseDetails) {
        let numberofQuestions = 0;
        let attemptedQuestions = 0;
        if (resourceType === 'TEST_SERIES') {
          const response = javaApi.post('app-test-series-ws/api/v1/test-series/test/details', {
            testIds: [testId || id],
            courseId: courseId?.courseId || courseDetails?.courseId,
          });
          numberofQuestions = response?.data?.data?.numberOfQuestions;
          attemptedQuestions = response?.data?.data?.attemptedQuestions;
        }
        let player_sourceVar = sessionStorage?.getItem('sourceURL') || 'home';
        if (player_sourceVar === 'home') {
          player_sourceVar = 'continue_watching';
        } else {
          player_sourceVar = 'normal';
        }
        if (item?.feedType !== 'QUIZ') {
          try {
            trackMoengageEvent('topic_landed', {
              course_category: courseDetails?.courseTypeName,
              course_id: courseDetails?.courseId,
              course_status: courseDetails?.coursePaymentStatus,
              course_type: courseDetails?.isCourseFree ? 'paid' : 'free',
              is_expiring_soon: courseDetails?.validityLeft <= 15 && courseDetails?.isCourseFree ? 'yes' : 'no',
              course_title: courseDetails?.courseTitle,
              topic_title: resourceDetails?.name || resource?.name,
              topics_count: courseDetails?.data?.length,
              player_source: player_sourceVar,
              source_screen: sessionStorage?.getItem('sourceURL') || 'home',
              topic_position: topicPosition || lessonposition,
              topic_type: resourceDetails?.resourceType ? resourceDetails?.resourceType : resourceType,
              completion_percentage:
                resourceType === 'TEST_SERIES'
                  ? Math.round((attemptedQuestions / numberofQuestions) * 100) || 0
                  : resourceType,
            });
          } catch (error) {
            /* empty */
          }
        }
      }
      if (resourceType === 'TEST_SERIES') {
        if (
          resource.testStatus === 'INCOMPLETE' ||
          resource.testStatus === 'START_NOW' || (val === 'reattempt' && resource?.testStatus === 'COMPLETED')
        ) {
          if (item?.feedType === 'QUIZ') {
            startTestRef.current = window.open(
              `/test-series/0/${testId || id}/`,
              '_blank',
              'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no',
            );
          } else {
            startTestRef.current = window.open(
              `/test-series/${courseId?.courseId || courseId}/${testId || id}`,
              '_blank',
              'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no',
            );
          }
        }
        if (resource.testStatus === 'COMPLETED') {
          if (item?.feedType === 'QUIZ') {
            testWindowRef.current = window.open(
              `/test-series/0/${testId || id}/result`,
              '_blank',
              'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no',
            );
          } else if (item.resultAvailable) {
            testWindowRef.current = window.open(
              `/test-series/${courseId?.courseId || courseId}/${testId || id}/result`,
              '_blank',
              'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no',
            );
          } else {
            toast.custom((toastId) => (
              <div id={toastId} className={styles.resultNotAvailableWrapper}>
                <button
                  type="button"
                  className={styles.closeBtn}
                  onClick={() => toast.dismiss(toastId)}
                  data-testid="close-btn"
                >
                  <FontAwesomeIcon icon={faTimes} size="6x" />
                </button>

                <div className={styles.resultNotAvailableContent}>
                  <Image
                    src="/images/test-result.png"
                    alt="Result not Available"
                    width={106}
                    height={99}
                    className={styles.image}
                  />

                  <div className={styles.content}>
                    <h6>Your Test has been successfully submitted.</h6>
                    {/* eslint-disable-next-line max-len */}
                    <p>Please sit back and relax while we process the results, which {new Date().getTime() >= item.resultTime ? 'has already been declared' : 'will be announced'} at <b>{moment(item.resultTime).format('Do MMM YYYY hh:mm a')}</b> and will be available in your course under the folder titled Prelims Weekly MCQ Test in PDF format.</p>
                  </div>
                </div>
              </div>
            ), { duration: 10000, dismissible: true });
          }
        }
        return;
      }
      if (resourceType === 'QUIZ') {
        window.open(`/quiz?type=primary&videoid=${contentId}&quizid=${contentId}&from=newWeb`);
        return;
      }

      if (
        resourceType === 'TEXT' &&
        (resource?.textInfo || resource?.textUploadUrl || resource?.text) &&
        ('textInfo' in resource ? !resource?.textInfo : true) &&
        resource?.textUploadUrl
      ) {
        const { assignedLessonId } = resource;
        const [courseID, languageId] = router.query.course.split('-');
        dispatch(
          saveUserPdfStatus({
            courseId: courseID,
            languageId,
            assignedLessonId: assignedLessonId || resourceDetails?.assignedLessonId,
            type: 3,
          }),
        );
        window.open(resource.textUploadUrl || resource.text, '_blank');
        return;
      }

      let route = `${router.asPath.replace(new RegExp(`(${lastFolder}\\/)?[^\\/]+$`), contentId)}/`;
      if (name) {
        route += convertToSlug(name);
      }

      router.push(
        {
          pathname: route.replace(/\/+/, '/'),
          query: {
            playlistId: lastFolder,
          },
        },
        undefined,
        { shallow: true },
      );
    }
  };

  const handleRedirection = (itemValue) => {
    const [courseID, languageId] = router?.query?.course?.split('-') || [];

    const { name, lessonSlug, contentId } = itemValue;
    if (currentRoute && currentRoute[1].includes('playlist') === true) {
      let routeValue = router.asPath.replace(/\/playlist\/\d+/, '/');
      routeValue += itemValue && itemValue.parents && itemValue.parents.map((parent) => parent.id).join('/');
      routeValue += `/${item.lessonSlug}`;
      return {
        pathname: routeValue,
        query: {
          playlistId: lastFolder,
        },
      };
    }

    if (parents) {
      const text = router.asPath;
      const data = text.split('/');
      const routeValue = data?.length ? text.replace(data[data.length - 2], parents[parents.length - 1].id) : '';
      return routeValue;
    }

    let route = `/course-content/${courseID}-${languageId}/`;
    if (Array.isArray(itemValue?.parents)) {
      route += itemValue.parents.map((parent) => parent.id).join('/');
    } else {
      route += `/${itemValue.id || ''}/${contentId}`;
    }

    if (name || lessonSlug) {
      route += `/${lessonSlug || convertToSlug(name)}`;
    }

    if (typeof window !== 'undefined' && window?.location?.search) {
      route += window.location.search.toString();
    }
    if (isBookMark) {
      const uniqueIds = [...new Set(resourceData?.parent?.map((obj) => obj?.id))];
      const result = uniqueIds.join('/');
      return `/course-content/${courseId}-${resourceData?.languageId}/${result}/${item?.slug}?isBookmark=1`;
    }

    return route.replace(/\/+/g, '/');
  };

  if (isAndroid && item?.contentType === 'VIDEO') {
    const handleAndroid = (event) => {
      event.preventDefault();
      dispatch(isAndroidModal({ show: true }));
    };

    return (
      <ResourceComponent
        item={item}
        courseId={courseId}
        resourceData={resourceData}
        isBookMark={isBookMark}
        hideInfoBar={hideInfoBar}
        onClick={handleAndroid}
      />
    );
  }

  if (updatesTap !== 'myPlaylist') {
    switch (item?.contentType) {
      case 'VIDEO':
        if (
          updatesTap === 'updatesTap'
            ? (item?.s3_enabled === 1 && !item?.s3_url) ||
              (item?.is_drm_enabled === 1 && !item?.drm_provider && !item?.uuid)
            : !item?.contentId || !item?.referenceId
        ) {
          return (
            <>
              {isModal && <NoVideo isModal={isModal} setIsModal={setIsModal} />}
              <ResourceComponent
                item={item}
                data-testid="resource-item"
                courseId={courseId}
                videoDash={videoDash}
                updatesTap={updatesTap}
                blockContentKey="blockContentKey"
                isBookMark={isBookMark}
                hideInfoBar={hideInfoBar}
                onClick={(event) => {
                  event.preventDefault();
                  setIsModal(true);
                }}
              />
            </>
          );
        }
        break;
      case 'TEXT':
        if (updatesTap === 'updatesTap' ? item?.type === 2 && !item?.text : !item?.textUploadUrl && !item?.textInfo) {
          return (
            <>
              {isModal && <NoVideo isModal={isModal} setIsModal={setIsModal} />}
              <ResourceComponent
                item={item}
                courseId={courseId}
                videoDash={videoDash}
                updatesTap={updatesTap}
                blockContentKey="blockContentKey"
                isBookMark={isBookMark}
                hideInfoBar={hideInfoBar}
                onClick={(event) => {
                  event.preventDefault();
                  setIsModal(true);
                }}
              />
            </>
          );
        }
        break;
      case 'QUIZ':
        if (!item?.contentId) {
          return (
            <>
              {isModal && <NoVideo isModal={isModal} setIsModal={setIsModal} />}
              <ResourceComponent
                item={item}
                courseId={courseId}
                videoDash={videoDash}
                updatesTap={updatesTap}
                blockContentKey="blockContentKey"
                hideInfoBar={hideInfoBar}
                onClick={(event) => {
                  event.preventDefault();
                  setIsModal(true);
                }}
              />
            </>
          );
        }
        break;
      case 'TEST_SERIES':
        if (updatesTap === 'updatesTap' ? !item?.id : !item?.testId) {
          return (
            <>
              {isModal && <NoVideo isModal={isModal} setIsModal={setIsModal} />}
              <ResourceComponent
                item={item}
                courseId={courseId}
                videoDash={videoDash}
                updatesTap={updatesTap}
                blockContentKey="blockContentKey"
                hideInfoBar={hideInfoBar}
                onClick={(event) => {
                  event.preventDefault();
                  setIsModal(true);
                }}
              />
            </>
          );
        }
        break;
      default:
        break;
    }
  }

  if (isBookMark) {
    return (
      <>
        {isSpinLoading && <NoVideo isModal={isSpinLoading} setIsModal={setIsModal} isBookMark={isBookMark} />}
        <ResourceComponent
          item={item}
          href={handleRedirection(item)}
          courseId={courseId}
          videoDash={videoDash}
          updatesTap={updatesTap}
          blockContentKey={blockContentKey}
          resourceData={resourceData}
          isBookMark={isBookMark}
          hideInfoBar={hideInfoBar}
          onClick={() => setIsSpinLoading(true)}
        />
      </>
    );
  }

  if (
    item?.elementContentType === 3 ||
    (currentRoute && currentRoute[1].includes('playlist') === true) ||
    item?.video_type === 1
  ) {
    return (
      <ResourceComponent
        item={item}
        href={handleRedirection(item)}
        courseId={courseId}
        videoDash={videoDash}
        updatesTap={updatesTap}
        blockContentKey={blockContentKey}
        resourceData={resourceData}
        hideInfoBar={hideInfoBar}
      />
    );
  }

  if (item?.contentType !== 'VIDEO' && resource?.testStatus === 'COMPLETED') {
    return (
      <ResourceComponent
        handleClick={handleClick}
        item={item}
        courseId={courseId}
        videoDash={videoDash}
        updatesTap={updatesTap}
        blockContentKey={blockContentKey}
        hideInfoBar={hideInfoBar}
      />
    );
  }

  if (item?.contentType !== 'VIDEO') {
    return (
      <ResourceComponent
        onClick={handleClick}
        item={item}
        courseId={courseId}
        videoDash={videoDash}
        updatesTap={updatesTap}
        blockContentKey={blockContentKey}
        hideInfoBar={hideInfoBar}
      />
    );
  }

  return (
    <>
      {isModal && <NoVideo isModal={isModal} setIsModal={setIsModal} />}
      <ResourceComponent
        item={item}
        courseId={courseId}
        lessonposition={lessonposition}
        topicCount={topicCount}
        setTopicCount={setTopicCount}
        isUpdateLanguage={isUpdateLanguage}
        setIsUpdateLanguage={setIsUpdateLanguage}
        blockContentKey={blockContentKey}
        updatesTap={updatesTap}
        isBookMark={isBookMark}
        hideInfoBar={hideInfoBar}
      />
    </>
  );
}

export default CourseItemResourceComponent;
