import React, { useMemo, useState } from 'react';

import Image from 'next/image';

import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
// Redux
import { useDispatch, useSelector } from 'react-redux';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';

import { hideLogin, setUserExamPreference } from '../../../redux/slices/authSlice/actions';

import { javaApi } from '../../../utils/AxiosInstance';

import NotFoundContainer from '../../../container/NotFound';
// Components
import Header from '../Header';
import styles from './ExamPreference.module.scss';

function GoalItem({ goal, onClick, categoryId }) {
  return (
    <div
      className={`${styles.goalItem} ${categoryId === goal?.id ? styles.highlighted : ''}`}
      data-testid={`exam-preference-button-goal-${goal.id}`}
      onClick={onClick}
    >
      <div className={styles.goalItemIconWrapper}>
        <Image className={styles.goalItemIcon} src={goal.image} alt={goal.name} width={18} height={18} />
      </div>
      <div className={styles.goalItemContent}>
        <span className={styles.goalItemName}>{goal.name}</span>
      </div>
    </div>
  );
}

function ExamPreferenceComponent({ examPrefModal, setExamPrefModal }) {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const [examPreference, setExamPreference] = useState([]);
  const trackMoengageEvent = useMoengageEventTracker();
  const { selectedExamPreference } = useSelector((state) => ({
    selectedExamPreference: state?.accountInfo?.userExamPreferenceDetails,
  }));
  const { categoryId } = selectedExamPreference?.data?.[0] || {};

  const { popularGoals, otherGoals } = useMemo(() => {
    const filteredGoals = examPreference.filter(
      (value) => searchInput === '' || value?.name?.indexOf(searchInput) >= 0,
    );
    if (searchInput?.trim()?.length > 3) {
      try {
        trackMoengageEvent('goal_searched', {
          goal_searched: searchInput,
        });
      } catch (error) {
        /* empty */
      }
    }

    return {
      popularGoals: filteredGoals.filter((filteredGoal) => filteredGoal?.sortOrder === 1),
      otherGoals: filteredGoals.filter((filteredGoal) => filteredGoal?.sortOrder !== 1),
    };
  }, [searchInput, examPreference]);

  React.useEffect(() => {
    (async () => {
      const response = await javaApi.get('/app-content-ws/api/web/exam/preference');
      if (response?.data?.success) {
        setExamPreference(response?.data?.data);
      } else {
        setExamPreference([]);
      }
    })();
  }, []);

  const handleGoalSearch = (event) => setSearchInput(event.currentTarget.value);
  const onGoalSelect = (goal, value) => () => {
    if (goal?.id) {
      try {
        trackMoengageEvent('user_exam_selected', {
          goal_type: value,
          exam_selected: goal?.name,
        });
      } catch (error) {
        /* empty */
      }
    }
    dispatch(setUserExamPreference(goal?.id));
    if (examPrefModal) {
      setExamPrefModal(false);
    }
  };

  const handleOnBack = () => {
    try {
      trackMoengageEvent('login_dismissed', {
        drop_point: 'exam_preference',
      });
    } catch (error) {
      /* empty */
    }
    dispatch(hideLogin());
    if (examPrefModal) {
      setExamPrefModal(false);
    }
  };

  return (
    <div id="exam-preference-screen" className={styles.examPreferenceScreen}>
      <Header title="Exam Preference" onBack={handleOnBack} backIcon={faXmark} />

      <p id="exam-preference-subtitle" className={styles.mainSubtitle}>
        This helps us to provide you a better experience.
      </p>

      <div className={styles.goalSearchWrapper}>
        <span className={styles.goalSearchIconWrapper}>
          <FontAwesomeIcon icon={faSearch} />
        </span>
        <input
          id="goal-search-input"
          type="text"
          className={styles.goalSearchInput}
          onChange={handleGoalSearch}
          placeholder="Search Your Goal"
          data-testid="exam-preference-input-search"
        />
      </div>

      <div
        className={classNames(styles.goalsWrapper, {
          [styles.isGoalsAvailable]: popularGoals?.length === 0 && otherGoals?.length === 0,
        })}
      >
        {popularGoals?.length > 0 && (
          <div className={styles.popularGoals}>
            <h5 className={styles.goalsWrapperTitle}>Popular Goals</h5>

            <div className={styles.goalsListWrapper}>
              {popularGoals?.map((goal) => (
                <GoalItem
                  key={`exam-preference-${goal.id}`}
                  goal={goal}
                  onClick={onGoalSelect(goal, 'popular')}
                  categoryId={categoryId}
                />
              ))}
            </div>
          </div>
        )}

        {otherGoals?.length > 0 && (
          <div className={styles.otherGoals}>
            <h5 className={styles.goalsWrapperTitle}>Other Goals</h5>

            <div id="other-goals" className={styles.goalsListWrapper}>
              {otherGoals?.map((goal) => (
                <GoalItem
                  id={`exam-preference-${goal.id}`}
                  key={`exam-preference-${goal.id}`}
                  goal={goal}
                  onClick={onGoalSelect(goal, 'other')}
                  categoryId={categoryId}
                />
              ))}
            </div>
          </div>
        )}
        {popularGoals?.length === 0 && otherGoals?.length === 0 && <NotFoundContainer title="No Results Found" />}
      </div>
    </div>
  );
}

export default ExamPreferenceComponent;
