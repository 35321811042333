import React from 'react';
import classNames from 'classnames';

import { decipherString } from '../../../../utils/helper';

import styles from './AnswerOption.module.scss';

function AnswerOption({ type = 'checkbox', index, value, label, readOnly, solution, ...rest }) {
  return (
    <label
      className={classNames(styles.wrapper, {
        [styles.isSelected]: parseInt(value, 10) === parseInt(index, 10),
        [styles.readOnly]: readOnly,
        [styles.isRightAnswer]: solution?.correctAns === parseInt(index, 10),
        [styles.isWrongAnswer]: solution?.userAns === index && solution?.correctAns !== index,
      })}
    >
      <input
        type={type}
        value={index}
        style={{ display: 'none' }}
        checked={parseInt(value, 10) === parseInt(index, 10)}
        data-testid={`answer-option-input-${String.fromCharCode(65 + index)}`}
        {...rest}
      />

      <span className={styles.indexWrapper}>{String.fromCharCode(65 + index)}</span>
      <span
        className={styles.labelWrapper}
        dangerouslySetInnerHTML={{ __html: decipherString(label) }}
      />
    </label>
  );
}

export default AnswerOption;
