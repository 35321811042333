import React from 'react';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import AppleLogo from '../../../assets/images/AppleLogo.svg';

import RippleButton from './RippleButton';

function AppStoreBtn({ position, isFooter }) {
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();
  const handleAppleStore = () => {
    try {
      trackMoengageEvent('app_store_clicked', {
        source_screen: screen,
        position,
      });
    } catch (error) {
      /* empty */
    }
    window.open('https://apps.apple.com/in/app/adda247/id6451410946', '_blank');
  };
  return (
    <RippleButton
      className="AppStoreBtn_Globals"
      onClick={handleAppleStore}
      rippleColor="#fff"
      opacity={0.38}
    >
      <div className="container">
        <div className="side-by-side">
          <div className="first-div">
            <AppleLogo />
          </div>
          <div className="second-div">
            <span className="sub-div-1">Download on the</span>
            <div className={`sub-div-2 ${isFooter === 'true' ? 'is-footer-margin' : ''}`}>App Store</div>
          </div>
        </div>
      </div>
    </RippleButton>
  );
}

export default AppStoreBtn;
