/* eslint-disable camelcase, react/button-has-type, import/no-unresolved */
import React, { useEffect, useId, useRef } from 'react';

import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { isAndroidModal } from '../../../redux/slices/courseConsumption';
import { getParentsForMultipleCourses } from '../../../redux/slices/librarySlice';

import { convertToSlug, getDeviceTypeAndroid } from '../../../utils/helper';

import styles from './WatchList.module.scss';

const NewThumbnail = dynamic(() => import('../../Common/NewThumbnail/NewThumbnail'));

function WatchList({ headingShow, newCourseConsumptionEnable }) {
  const sliderId = useId();

  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const paginationRef = useRef(null);
  const testWindowRef = React.useRef();
  const router = useRouter();
  const dispatch = useDispatch();
  const [isAndroid, setIsAndroid] = React.useState(false);
  const [continueWatchState, setContinueWatchState] = React.useState(null);
  const continueWatchList = useSelector((state) => state.library.continueWatch?.list);

  React.useEffect(() => {
    if (continueWatchList?.length > 0) {
      setContinueWatchState(continueWatchList);
    }
  }, [continueWatchList]);

  const createVideoDashboardRoute = (video) => {
    const parentPath = video?.parentObject?.map((parent) => parent.id).join('/');
    let route = `/course-content/${video.courseId}-${video.languageId}/`;
    route += parentPath.replace(/\d+$/, '');
    route += `${video.lessonId}/${convertToSlug(video.lessonName)}`;
    sessionStorage?.setItem('sourceURL', 'home');
    return route;
  };

  const handleMweb = () => {
    dispatch(isAndroidModal({ show: true }));
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { userAgent } = window.navigator;
      setIsAndroid(getDeviceTypeAndroid(userAgent));
    }
  }, []);

  const handleTestContent = (video) => {
    const { testStatus, courseId, mappingId } = video;
    if (testStatus === 'START_NOW' || testStatus === 'INCOMPLETE') {
      testWindowRef.current = window.open(
        `/test-series/${courseId || 1}/${mappingId}`,
        '_blank',
        'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no',
      );
    } else if (testStatus === 'COMPLETED') {
      testWindowRef.current = window.open(
        `/test-series/${courseId}/${mappingId}/result`,
        '_blank',
        'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no',
      );
    }
  };

  const new_course_consumption_enable = useSelector((state) => state.auth?.authUser?.newCourseConsumptionEnable);

  const goToVideoDashboardToggle = async (video) => {
    if (isAndroid) {
      handleMweb();
      return;
    }
    if (video?.contentType === 'QUIZ') {
      handleTestContent(video);
      return;
    }
    const redirectionFlag = newCourseConsumptionEnable || new_course_consumption_enable;
    let redirectionLink = '';
    const { courseId, languageId, lessonId } = video;
    if (redirectionFlag) {
      const parentsData = await getParentsForMultipleCourses([
        {
          courseId,
          languageId,
          lessonId,
        },
      ]);
      const parentObject = parentsData[`${courseId}-${lessonId}`];
      redirectionLink = createVideoDashboardRoute({ ...video, parentObject });
      router.push(redirectionLink);
      // window.location.href = redirectionLink;
    } else {
      window.localStorage.setItem(
        'routeState',
        JSON.stringify({
          courseID: video.courseId,
          languageID: video.languageId,
          lessonId: video.lessonId,
          courseSlug: video.courseSlug,
          videoTotalTime: video.videoTotalTime,
          fromContinueWatch: true,
        }),
      );
      window.location.href = '/video-dashboard';
    }
  };

  const getTestBtnStatus = (video) => {
    const { testStatus } = video;
    let buttonText;
    switch (testStatus) {
      case 'COMING_SOON':
        buttonText = 'Coming Soon';
        break;
      case 'INCOMPLETE':
        buttonText = 'Resume';
        break;
      case 'COMPLETED':
        buttonText = 'View Result';
        break;
      default:
        buttonText = 'Start Now';
        break;
    }
    return buttonText;
  };

  React.useEffect(() => {
    const interval = window.setInterval(async () => {
      if (testWindowRef?.current?.closed) {
        window.clearInterval(interval);
        window.location.reload();
      }
    }, 100);
  }, [testWindowRef]);

  if (!continueWatchState?.length) return null;

  return (
    <>
      {!headingShow && (
        <div className={styles.liveClassList_title}>
          <p className={styles.title}>Continue your preparation</p>
        </div>
      )}
      <div className={styles.continueWatchSliderWrapper}>
        <div className={styles.continueWatchSliderNavigation}>
          <button
            type="button"
            ref={navigationPrevRef}
            data-testid="watch-list-slider-nav-left"
            className={`${styles.continueWatchSliderNavLeft}  shadowOnHover`}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <button
            type="button"
            ref={navigationNextRef}
            data-testid="watch-list-slider-nav-right"
            className={`${styles.continueWatchSliderNavRight} shadowOnHover`}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <Swiper
          id={sliderId}
          className={styles.continueWatchSlider}
          modules={[Navigation, Pagination]}
          slidesPerView="auto"
          spaceBetween={13}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          pagination={{
            el: `.${styles.continueWatchSliderPagination}`,
            clickable: true,
          }}
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              if (swiper.params) {
                // Override prevEl & nextEl now that refs are defined
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;

                // Re-init navigation
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            });
          }}
        >
          {continueWatchState?.map((video) => {
            let videoPercentage = Math.floor((video.lastTimestamp * 100) / video.videoTotalTime);

            videoPercentage = videoPercentage >= 1 ? videoPercentage : 1;
            return (
              <SwiperSlide key={video?.id} className={styles.continueWatchSlide}>
                <div
                  className={styles.continueWatchVideo}
                  onClick={() => {
                    goToVideoDashboardToggle(video);
                  }}
                  data-testid="watch-list-video"
                >
                  <div className={styles.continueWatchVideoHeader}>
                    <div className={styles.continueWatchVideoImageWrapper}>
                      <NewThumbnail
                        courseDetails={video}
                        icon={video?.contentType === 'Video' && 'play'}
                        courseTypeId={video?.courseTypeId}
                      />
                    </div>

                    <div className={styles.continueWatchVideoProgressBar}>
                      <span
                        className={styles.continueWatchVideoProgressRange}
                        style={{ width: `${videoPercentage}%` }}
                      />
                    </div>
                  </div>
                  <div className={styles.continueWatchVideoContent}>
                    {video?.contentType === 'Video' && (
                      <div className={styles.continueWatchVideoProgress}>{videoPercentage}% Completed</div>
                    )}
                    {video?.contentType === 'QUIZ' && (
                      <div className={styles.continueWatchVideoProgress}>
                        {video?.noOfQuestions} Ques * {Number(video?.duration) / 60} Mins
                      </div>
                    )}
                    <div className={styles.continueWatchVideoDescription}>{video?.lessonName || video?.title}</div>
                  </div>
                  <div className={styles.continueWatchVideoFooter}>
                    {video?.contentType === 'Video' && (
                      <div>
                        <a className={styles.continueWatchVideoBtn} data-testid="watch-list-video-btn">
                          Continue watching
                        </a>
                      </div>
                    )}
                    {video?.contentType === 'QUIZ' && (
                      <div>
                        <a className={styles.continueWatchVideoBtn} data-testid="watch-list-video-btn">
                          {getTestBtnStatus(video)}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div ref={paginationRef} className={styles.continueWatchSliderPagination} />
      </div>
    </>
  );
}

export default WatchList;
