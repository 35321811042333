import React from 'react';

import styles from './Switch.module.scss';

function Switch({ children, ...rest }) {
  return (
    <label className={styles.wrapper}>
      {children && <span className={styles.label}>{children}</span>}

      <input
        type="checkbox"
        className={styles.input}
        {...rest}
        data-testid="switch"
      />
      <span className={styles.switchBtn} />
    </label>
  );
}

export default Switch;
