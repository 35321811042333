/* eslint-disable import/no-unresolved, max-len */
import Image from 'next/image';
import { useMoengageEventTracker } from 'src/hooks/useMoengage';
import useSourceScreen from 'src/hooks/useSourceScreen';

import PlayIcon from '../../assets/images/icon/play.svg';

import styles from './Testimonial.module.scss';

function StoreTestimonial({ testimonial, onViewClick }) {
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();
  const testimonialVideoPlayClick = () => {
    try {
      trackMoengageEvent('testimonial_video_play', {
        screen_source: screen,
        testimonial_id: testimonial?.id,
        testimonial_name: testimonial?.name,
        testimonial_video_id: testimonial?.uuid,
      });
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div className={styles.userInfoWrapper}>
          <div className={styles.userImageWrapper}>
            <Image
              src={testimonial.profileImage}
              className={styles.userImage}
              alt={testimonial.name}
              height={76}
              width={76}
            />
          </div>
          <div className={styles.userInfo}>
            <span className={styles.userInfoName}>{testimonial.name}</span>
            <span className={styles.userInfoRank}>{testimonial.achievement}</span>
          </div>
        </div>

        <button
          type="button"
          className={styles.userPlayIcon}
          onClick={testimonialVideoPlayClick}
          onClickCapture={onViewClick}
          data-testid="testimonial-play-icon"
        >
          <PlayIcon />
        </button>
      </header>

      <p className={styles.userTestimonial}>{testimonial.description}</p>
    </div>
  );
}

export default StoreTestimonial;
