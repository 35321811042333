import React from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Header({ title, backIcon = faArrowLeft, onBack }) {
  return (
    <header id="auth-modal-header" className="auth-modal--header">
      {typeof onBack === 'function' && (
        <button
          id="auth-modal-header-close"
          type="button"
          className="auth-modal--header-close"
          onClick={onBack}
          data-testid="auth-header-button-close"
        >
          <FontAwesomeIcon icon={backIcon} />
        </button>
      )}
      {title && <h4 id="auth-modal-header-title" className="auth-modal--header-title">{title}</h4>}
    </header>
  );
}
