/* eslint-disable camelcase, react/jsx-no-useless-fragment, max-len */
import React, { useState } from 'react';

import { Cookie } from 'next-cookie';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable import/extensions,import/no-unresolved */
import NotificationBell from '@/components/NotificationBell';
/* eslint-enable import/extensions,import/no-unresolved */

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useTimer from '../../../hooks/useTimer';

import { isAndroidModal } from '../../../redux/slices/courseConsumption';

import calenderIcon from '../../../assets/images/calendar_icon.png';
import CardsCalenderIcon from '../../../assets/images/calenderIcon.svg';
import liveClassIcon from '../../../assets/images/course-dashboard/icons/LiveClassIcon.gif';
import LiveClassPlay from '../../../assets/images/course-dashboard/icons/liveclass-play.svg';

// eslint-disable-next-line import/no-unresolved
import { LIVE_CLASS_STUDENT_JOIN_URL } from '../../../constants/api';
import { convertToSlug, formatDateCustom, getDeviceTypeAndroid } from '../../../utils/helper';

import styles from './LiveClassCard.module.scss';

function StatusIcon({ icon, iconImg, className, children }) {
  return (
    <span className={classNames(styles.statusBadge, className)}>
      {icon && <FontAwesomeIcon className={styles.statusBadgeIcon} icon={icon} />}
      {iconImg && (
        <span className={styles.statusBadgeIconImg}>
          <Image src={liveClassIcon} alt="Live" height={12} width={12} />
        </span>
      )}
      <span className={styles.statusBadgeText}>{children}</span>
    </span>
  );
}

function LiveClassStatus({ liveClass }) {
  const isEnded = moment().isSameOrAfter(liveClass.endTime);

  // On Ended
  if (isEnded && liveClass.status !== 3) {
    return <StatusIcon className={styles.classEnded}>Live Class Ended</StatusIcon>;
  }

  if (liveClass.live === true) {
    return (
      <StatusIcon className={styles.classLive} iconImg={liveClassIcon}>
        Live
      </StatusIcon>
    );
  }

  // On Re-Scheduled
  if (liveClass.status === 2) {
    return (
      <StatusIcon className={styles.classRescheduled} icon={faCircleExclamation}>
        Re-Scheduled
      </StatusIcon>
    );
  }

  if (liveClass.status === 3) {
    return (
      <StatusIcon className={styles.classCancelled} icon={faCircleExclamation}>
        Cancelled
      </StatusIcon>
    );
  }

  return null;
}

function ActionButton({ liveClass, onRangeChange, onTimeExpire, onWindowClose, homeMyLibFlag }) {
  const { isInMarginRange, isExpired } = useTimer({
    startTimestamp: liveClass.startTime,
    expiryTimestamp: liveClass.endTime,
    onRangeChange,
    onMarginRangeChange: onRangeChange,
    startTimestampMargin: 600, // Seconds
    endTimestampMargin: 0,
    onTimeExpire,
  });

  const [isAndroid, setIsAndroid] = React.useState(false);
  const AuthUserCity = useSelector((state) => state.auth?.authUser?.city);
  const new_course_consumption_enable = useSelector((state) => state.auth?.authUser?.newCourseConsumptionEnable);
  const router = useRouter();
  const dispatch = useDispatch();
  const trackMoengageEvent = useMoengageEventTracker();

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const { userAgent } = window.navigator;
      setIsAndroid(getDeviceTypeAndroid(userAgent));
    }
  }, []);

  const getRecordedSessionUrl = () => {
    let url = '';
    const videoPath = (liveClass.parent || []).map((l) => l.id).join('/');
    if (new_course_consumption_enable === 1) {
      url = `/course-content/${liveClass.courseId}-${liveClass.languageId}/${videoPath}/${convertToSlug(
        liveClass.video.lessonName,
      )}`;
      router.push(url);
    } else {
      const {
        assignedLessonId,
        cruxStatus,
        drmProvider,
        elementContentType,
        embedCode,
        isDrmEnabled,
        mediaId,
        pptStatus,
        quizStatus,
        resourceStatus,
        s3Enabled,
        s3Url,
        snippetStatus,
        uuid,
        videoId,
        videoType,
        videoTypeId,
      } = liveClass.video;
      const videoObj = {
        assigned_lesson_id: assignedLessonId,
        crux_status: cruxStatus,
        date: '',
        drm_provider: drmProvider,
        element_content_type: elementContentType,
        embed_code: embedCode,
        id: '',
        is_drm_enabled: isDrmEnabled,
        media_id: mediaId,
        name: '',
        ppt_status: pptStatus,
        quiz_status: quizStatus,
        resource_status: resourceStatus,
        s3_enabled: s3Enabled,
        s3_url: s3Url,
        snippet_status: snippetStatus,
        testseries_id: '',
        upcoming_date: '',
        upcoming_status: '',
        uuid,
        video_id: videoId,
        video_type: videoType,
        video_type_id: videoTypeId,
      };
      window.localStorage.setItem(
        'routeState',
        JSON.stringify({
          courseID: liveClass?.courseId,
          languageID: liveClass?.languageId,
          lessonId: liveClass?.video?.lessonId,
          courseSlug: convertToSlug(liveClass?.courseName),
          videoTotalTime: liveClass?.video?.videoTotalTime,
          fromContinueWatch: false,
          video: videoObj,
        }),
      );
      window.location.href = '/video-dashboard';
    }
  };

  // eslint-disable-next-line consistent-return
  const handleLiveClassJoin = () => {
    if (isAndroid) {
      dispatch(isAndroidModal({ show: true }));
    } else {
      const url = new URL(LIVE_CLASS_STUDENT_JOIN_URL);

      const cookies = new Cookie();
      const userId = cookies.get('userID');
      const apiToken = cookies.get('apiToken');

      url.pathname = url.pathname.concat('/');

      url.searchParams.append('vc', liveClass.externalScheduleId);
      url.searchParams.append('packageId', liveClass.courseId);
      url.searchParams.append('topic', liveClass.topic);
      url.searchParams.append('name', liveClass.facultyName);
      url.searchParams.append('userId', userId);
      url.searchParams.append('token', apiToken);
      url.searchParams.append('vendor', 1);
      const connectionType = window?.navigator?.connection?.effectiveType;
      try {
        trackMoengageEvent('siq_live_start', {
          external_schedule_id: liveClass?.externalScheduleId,
          course_name: liveClass.courseName,
          course_id: liveClass?.courseId,
          topic: liveClass?.topic,
          teacher_name: liveClass?.facultyName,
          start_schedule_time: moment(liveClass?.startTime).format('HH:mm'),
          source_screen: 'live_class_tab',
          network_type: connectionType,
          city: AuthUserCity || '',
        });
      } catch (error) {
        /* empty */
      }
      const liveClassWindow = window.open(url.toString(), '_blank');
      if (typeof onWindowClose === 'function') {
        const interval = window.setInterval(() => {
          if (!liveClassWindow.closed) return;

          onWindowClose(liveClass);
          window.clearInterval(interval);
        }, 100);

        return () => window.clearInterval(interval);
      }
    }
  };

  const handleRecordedSessionClick = () => {
    if (isAndroid) {
      dispatch(isAndroidModal({ show: true }));
    } else {
      getRecordedSessionUrl();
    }
  };

  if (liveClass.video) {
    return (
      <button
        type="button"
        className={classNames(styles.joinNowBtn, {
          [styles.Home_Lib_offset_font]: homeMyLibFlag,
        })}
        data-testid="liveclass-button-view-recorded"
        onClick={handleRecordedSessionClick}
      >
        View recorded session
      </button>
    );
  }

  if (isExpired && liveClass.status !== 3) {
    return (
      <span
        className={classNames(styles.infoText, {
          [styles.Home_Lib_infoText_offset]: homeMyLibFlag,
        })}
      >
        <p
          className={classNames(styles.recorded_Session, {
            [styles.Home_Lib_offset]: homeMyLibFlag,
          })}
        >
          Recorded session will be available soon
        </p>
      </span>
    );
  }
  if (liveClass.status === 3 && liveClass?.isOfflineClassVisibleOnline !== false) {
    return (
      <button
        type="button"
        className={classNames(styles.joinNowBtn, {
          [styles.Home_Lib_offset_font]: homeMyLibFlag,
        })}
        data-testid="liveclass-button-join-now"
        disabled
        onClick={handleLiveClassJoin}
      >
        Join Now
      </button>
    );
  }

  // if class is rescheduled
  if (liveClass.status === 2 && !isInMarginRange && moment(liveClass.startTime).isSame(moment(), 'date') === false && liveClass?.isOfflineClassVisibleOnline !== false) {
    return (
      <>
        {!homeMyLibFlag ? (
          <span className={styles.infoText}>
            This class rescheduled on {moment(liveClass.startTime).format('DD/MM/YYYY')}
          </span>
        ) : (
          <button
            type="button"
            className={classNames(styles.joinNowBtn, {
              [styles.Home_Lib_offset_font]: homeMyLibFlag,
            })}
            data-testid="liveclass-button-join-now"
            disabled={liveClass.live === false}
            onClick={handleLiveClassJoin}
          >
            Join Now
          </button>
        )}
      </>
    );
  }
  if (liveClass?.offlineClassMessage && !liveClass?.live) {
    return (
      <>
        <span className={styles.infoText}>
          {liveClass?.offlineClassMessage}
        </span>
      </>
    );
  }

  return (
    <>
      {liveClass?.isOfflineClassVisibleOnline === false ? '' :
    (
      <button
        type="button"
        className={classNames(styles.joinNowBtn, {
        [styles.Home_Lib_offset_font]: homeMyLibFlag,
      })}
        data-testid="liveclass-button-join-now"
        disabled={liveClass.live === false}
        onClick={handleLiveClassJoin}
      >
        Join Now
      </button>
    )}
    </>
  );
}

function LiveClassComponent({
  details: liveClass,
  onRangeChange,
  onTimeExpire,
  onWindowClose,
  onNotificationChange,
  from,
  liveclasses,
  compact = true,
  ...rest
}) {
  const homeMyLibFlag = from === 'my_library' || from === 'home';
  const liveClassDate = new Date(liveClass?.startTime).getDate();
  const currentDate = new Date().getDate();
  const [hoverCard, setHoverCard] = useState(false);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage?.setItem('backButtonRoute', 'live-class');
    }
  }, []);

  // eslint-disable-next-line no-unneeded-ternary
  const rescheduledIconFlag = !!(liveClassDate > currentDate && homeMyLibFlag);

  if (rest.skeleton) {
    return (
      <>
        {[...Array(rest.skeleton)].map((_, key) => (
          <div
            className={styles.skeleton}
            // eslint-disable-next-line react/no-array-index-key
            key={`skeleton-${key}`}
            data-testid="liveclass-skeleton"
          >
            <div className={styles.thumbnailWrapper}>
              <LiveClassPlay className={styles.thumbnailIcon} />
            </div>

            <div className={styles.contentWrapper}>
              <div className={styles.infoWrapper}>
                <span
                  className={classNames(styles.title, {
                    [styles.title_mylib_home]: homeMyLibFlag,
                  })}
                />
                <span className={styles.author} />
                <span className={styles.dateWrapper} />
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.hoverState_liveClassCard]: hoverCard && homeMyLibFlag,
        [styles.isCompact]: compact,
        [styles.haveNotificationEnabled]: liveClass?.isNotificationEnabled,
      })}
      onMouseEnter={() => {
        setHoverCard(true);
      }}
      onMouseLeave={() => {
        setHoverCard(false);
      }}
      data-testid="liveclass-card"
    >
      <div
        className={classNames(styles.thumbnailWrapper, {
          [styles.home_mylib_thumbnail_offset]: homeMyLibFlag,
        })}
      >
        <span className={styles.thumbnailTitle}>{liveClass.topic}</span>
        <LiveClassPlay className={styles.thumbnailIcon} />

        {compact === false && <LiveClassStatus liveClass={liveClass} />}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <h4
            className={classNames(styles.title, {
              [styles.title_mylib_home]: homeMyLibFlag,
            })}
          >
            {liveClass.topic}
          </h4>
          {(homeMyLibFlag || compact === false) && (
            <span className={styles.course_name}>
              Course:{' '}
              <span
                className={classNames({
                  [styles.course_name_hover]: homeMyLibFlag,
                })}
              >
                {liveClass.courseName}
              </span>
            </span>
          )}
          <span className={styles.author}>By: {liveClass.facultyName}</span>
          {compact === false && liveclasses === 'upcoming' && (
            <span className={styles.calenderWrapper}>
              <CardsCalenderIcon />
              <span className={styles.date}>{moment(liveClass?.startTime).format('DD MMMM, YYYY')}</span>
            </span>
          )}
          <span className={styles.dateWrapper}>
            {rescheduledIconFlag ? (
              <>
                <span className={`${styles.calenderIconImg}`}>
                  <Image src={calenderIcon} alt="calender_icon" height={14} width={14} />
                </span>
                <span className={styles.date}>{formatDateCustom(liveClass.startTime)}</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faClock} />
                <span className={styles.date}>
                  {moment(liveClass.startTime).format('hh:mm A')} - {moment(liveClass.endTime).format('hh:mm A')}
                </span>
              </>
            )}
          </span>
        </div>

        <div className={styles.actionsWrapper}>
          <ActionButton
            liveClass={liveClass}
            onRangeChange={onRangeChange}
            onWindowClose={onWindowClose}
            onTimeExpire={onTimeExpire}
            homeMyLibFlag={homeMyLibFlag}
          />
        </div>
      </div>

      {compact && <LiveClassStatus liveClass={liveClass} />}

      <NotificationBell
        courseId={liveClass.courseId}
        isAllowed={
          liveClass?.isNotificationAllowed &&
          liveClass.status === 1 &&
          liveClass?.startTime > new Date().getTime() &&
          liveClass?.freeLiveClass !== true
        }
        isEnabled={liveClass?.isNotificationEnabled}
        from={liveClass?.freeLiveClass !== true ? 'today-live-class' : 'free-live-class'}
        forCourse={false}
        onChange={onNotificationChange}
      />
    </div>
  );
}

LiveClassComponent.defaultProps = {
  onWindowClose: null,
};

export default LiveClassComponent;
