/* eslint-disable react/no-danger, max-len */
import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import ShopHeaderSliderComponent from './Slider';

import useMediaQuery from '../../../hooks/useMediaQuery';

import styles from './Header.module.scss';

function ShopHeaderComponent({ courseMeta, topBanner }) {
  const isMatched = useMediaQuery('(max-width: 480px)');
  const headerContentRef = React.useRef(null);

  const [canHaveReadMore, setCanHaveReadMore] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    setIsExpanded(false);

    const observer = new ResizeObserver(([headerElem]) => {
      if (headerElem.target.scrollHeight > headerElem.target.clientHeight) {
        setCanHaveReadMore(true);
      } else if (headerElem.target.scrollHeight <= 96) {
        setCanHaveReadMore(false);
      }
    });

    const observerInterval = window.setInterval(() => {
      if (headerContentRef.current) {
        observer.observe(headerContentRef.current);
        window.clearInterval(observerInterval);
      }
    }, 100);

    return () => observer.disconnect();
  }, [courseMeta]);

  if (isMatched && !courseMeta?.menuHeader && !courseMeta?.menuDescription) return null;

  return (
    <header
      data-testid="shop-header-section"
      className={classNames(styles.wrapper, {
        [styles.withoutTitle]: !courseMeta?.menuHeader && !courseMeta?.topDescription,
      })}
    >
      {topBanner?.bannerList?.length && <ShopHeaderSliderComponent banners={topBanner?.bannerList} />}

      {courseMeta && (
        <div className={styles.headerContentWrapper}>
          {courseMeta?.menuHeader && <h1 className={styles.headerContentTitle}>{courseMeta?.menuHeader}</h1>}
          <span
            className={classNames(styles.headerContent, {
              [styles.isExpanded]: isExpanded,
              [styles.canHaveReadMore]: canHaveReadMore,
            })}
            ref={headerContentRef}
            dangerouslySetInnerHTML={{ __html: courseMeta?.topDescription }}
          />
          <div className={styles.showMoreContainer}>
            <button
              type="button"
              onClick={() => setIsExpanded((prev) => !prev)}
              data-testid="shop-header-button-toggle-read-more"
            >
              <span>{isExpanded ? 'Read Less' : 'Read More'}</span>
              <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
            </button>
          </div>
        </div>
      )}
    </header>
  );
}

export default ShopHeaderComponent;
