import React from 'react';

import { useRouter } from 'next/router';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import useLeadSquared from '../../../hooks/useLeadSquared';
import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import { showLogin } from '../../../redux/slices/authSlice/actions';

import PhoneIcon from '../../../assets/images/icon/phone.svg';
import SupportIcon from '../../../assets/images/icon/support.svg';
import SupportImg from '../../../assets/images/store/contact-us.svg';

import { javaApi } from '../../../utils/AxiosInstance';

import RequestCallbackModal from '../../RequestCallback/Modal';
import RequestCallbackSuccessModal from '../../RequestCallback/Modal/Success';
import RippleButton from '../../RippleButton';
import styles from './Support.module.scss';

function RequestCallbackActionBtn({ isAuthenticated, modalQuestions, requestCallback }) {
  const dispatch = useDispatch();

  if (!isAuthenticated) {
    return (
      <RippleButton
        type="button"
        className={styles.supportButton}
        onClick={() => dispatch(showLogin())}
      >
        Login to Request Callback
      </RippleButton>
    );
  }

  if (modalQuestions?.submitted) {
    return (
      <RippleButton
        type="button"
        className={styles.supportButton}
        disabled={modalQuestions?.submitted}
      >
        <span>Callback already requested</span>
      </RippleButton>
    );
  }

  return (
    <RippleButton
      type="button"
      className={styles.supportButton}
      onClick={requestCallback}
    >
      <span>Request a call from us</span>
    </RippleButton>
  );
}

function SupportComponent({ metaData: { supportNumber, supportWhatsappNumber } }) {
  const {
    query: { category },
  } = useRouter();

  const trackMoengageEvent = useMoengageEventTracker();
  const { generateLead } = useLeadSquared();
  const screen = useSourceScreen();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [showPopover, setShowPopover] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [modalQuestions, setModalQuestions] = React.useState({});

  const whatsappLinkClick = (num) => {
    try {
      trackMoengageEvent('whatsapp_support_clicked', {
        screen_source: screen,
        support_number: num,
      });
    } catch (error) {
      // Handle error
    }
    window.open(`https://api.whatsapp.com/send/?phone=91${supportWhatsappNumber}&text&type=phone_number`, '_blank');
  };

  const phoneSupportClick = () => {
    try {
      trackMoengageEvent('call_support_clicked', {
        screen_source: screen,
        support_number: supportNumber,
      });
    } catch (error) {
      // Handle error
    }
  };

  const fetchCallbackQuestions = React.useCallback(async () => {
    try {
      const response = await javaApi.get('store-ws/api/v1/store/callback', { params: { menuSlug: category } });
      setModalQuestions(response.data.data);
    } catch (e) {
      setModalQuestions({});
    }
  }, [category, setModalQuestions]);

  const handleOnSubmit = async () => {
    try {
      await javaApi.post('store-ws/api/v1/store/callback/status', null, { params: { menuSlug: category } });

      // Generate Lead
      await generateLead((authUser) => ({
        Opportunity: {
          OpportunityEventCode: 12000,
          OpportunityNote: 'Opportunity capture api overwrite',
          UpdateEmptyFields: true,
          DoNotPostDuplicateActivity: true,
          DoNotChangeOwner: true,
          Fields: [
            {
              SchemaName: 'mx_Custom_1',
              Value: `${authUser.mobileNumber}-${category.toUpperCase()}`,
            },
            {
              SchemaName: 'mx_Custom_2',
              Value: 'Fresh',
            },
            {
              SchemaName: 'mx_Custom_37',
              Value: 'STORE',
            },
            {
              SchemaName: 'mx_Custom_16',
              Value: category.toUpperCase(),
            },
            {
              SchemaName: 'Status',
              Value: 'Open',
            },
            {
              SchemaName: 'mx_Custom_53',
              Value: 'WEB',
            },
          ],
        },
      }));
    } catch (e) {
      return false;
    }

    await fetchCallbackQuestions();

    return true;
  };

  const requestCallback = () => {
    if (modalQuestions?.questions?.length !== 0) {
      setShowModal(!showModal);
      setShowPopover(false);
    } else {
      handleOnSubmit()
        .then(setIsSuccess)
        .catch(() => setIsSuccess(false));
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchCallbackQuestions();
    }
  }, [isAuthenticated, fetchCallbackQuestions]);

  if (!supportNumber && !supportWhatsappNumber) return null;

  return (
    <>
      <div className={styles.supportPopoverWrapper}>
        <div className={classNames(styles.popoverWrapper, { [styles.showPopover]: showPopover })}>
          <header className={styles.popoverHeader}>
            <div className={styles.popoverHeaderTitle}>
              <h3>Trouble in Purchase?</h3>
              <p>Our Counsellor can help you with all your queries.</p>
            </div>

            <SupportImg className={styles.popoverHeaderImg} />
          </header>

          <RequestCallbackActionBtn
            isAuthenticated={isAuthenticated}
            modalQuestions={modalQuestions}
            requestCallback={requestCallback}
          />

          <div className={styles.contactIconsWrapper}>
            {supportNumber && (
              <>
                <span>Or call us</span>
                <span className={styles.contactPhoneWrapper} onClick={phoneSupportClick} data-testid="support-phone">
                  <PhoneIcon />
                  <span className={styles.contactPhoneNumber}>
                    {String(supportNumber).replace(/([0-9]{3})/, '$1-')}
                  </span>
                </span>
              </>
            )}
            {supportWhatsappNumber && (
              <span className={styles.whatsappWrapper} onClick={whatsappLinkClick} data-testid="support-whatsapp">
                <span className={styles.whatsappIcon}>
                  <FontAwesomeIcon icon={faWhatsapp} />
                </span>
                <b>Chat</b>
              </span>
            )}
          </div>
        </div>

        <button
          type="button"
          className={classNames(styles.supportModalBtn, { [styles.isOpened]: showPopover })}
          onClick={() => setShowPopover((prevState) => !prevState)}
        >
          {showPopover ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M8 8.00003L25.0323 25.0323M8 25.0323L25.0323 8"
                stroke="#FCFCFC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <SupportIcon />
          )}
        </button>
      </div>

      {isAuthenticated && (
        <RequestCallbackModal
          show={showModal}
          supportNumber={supportNumber}
          modalQuestions={modalQuestions}
          onSubmit={handleOnSubmit}
          onClose={() => setShowModal(false)}
        />
      )}

      {isSuccess && <RequestCallbackSuccessModal show={3000} />}
    </>
  );
}

export default SupportComponent;
