import React, { useEffect, useState } from 'react';

import Image from 'next/image';

import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { showPaymentModal } from '../../redux/slices/librarySlice';

import CCAvenueLogo from '../../assets/images/cart/ccavenue.png';
import PaytmLogo from '../../assets/images/cart/paytm_logo.png';
import RazorpayLogo from '../../assets/images/cart/razorpay.svg';

import styles from './PaymentModal.module.scss';

function PaymentModal({
  paymentMethods,
  setPaymentGateway,
  isPaymentInitialized,
  setIsPaymentInitialized,
}) {
  const dispatch = useDispatch();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isShowPaymentOptions, setIsShowPaymentOptions] = useState(false);

  useEffect(() => {
    if (Object.keys(paymentMethods)?.length > 0 && isPaymentInitialized !== false) {
      // For Multiple Payment Methods
      if (Object.keys(paymentMethods)?.length > 1) {
        setIsShowPaymentOptions(true);
      } else {
        setPaymentGateway(Object.values(paymentMethods)[0]);
      }
    }
  }, [paymentMethods, isPaymentInitialized]);

  const handleCancel = () => {
    dispatch(showPaymentModal({ show: false }));

    setSelectedPaymentMode('');
    setIsShowPaymentOptions(false);

    // eslint-disable-next-line no-unused-expressions
    setIsPaymentInitialized && setIsPaymentInitialized(false);
  };

  const onPaymentOptionSelection = (value) => () => {
    setSelectedPaymentMode(value);
  };

  const onContinue = () => {
    setPaymentGateway(parseInt(selectedPaymentMode, 10));
  };

  return (
    <div className={styles.paymentModalWrapper}>
      <div className={styles.paymentModalContainer}>
        <div className={styles.modalHeader}>
          <div className={styles.modalTitle}>Select payment option</div>
          <div
            className={styles.closeIcon}
            onClick={handleCancel}
            data-testid="payment-modal-button-close"
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
        </div>
        <div className={styles.modalBody}>
          <form className={styles.formContainer}>
            {paymentMethods.CCAVENUE && (
              <label
                className={styles.labelContainer}
                htmlFor="payment-ccavenue"
              >
                <input
                  id="payment-ccavenue"
                  className={styles.inputPaymentMethod}
                  type="radio"
                  name="payment"
                  value={paymentMethods.CCAVENUE}
                  onChange={onPaymentOptionSelection(paymentMethods.CCAVENUE)}
                  defaultValue={selectedPaymentMode}
                  data-testid="payment-modal-input-ccavenue"
                />
                <span className={styles.imageWrapper}>
                  <Image src={CCAvenueLogo} alt="" height={18} width={97} />
                </span>
              </label>
            )}
            {paymentMethods.PAYTM && (
              <label
                htmlFor="payment-paytm"
                className={styles.labelContainer}
              >
                <input
                  id="payment-paytm"
                  className={styles.inputPaymentMethod}
                  type="radio"
                  name="payment"
                  value={paymentMethods.PAYTM}
                  onChange={onPaymentOptionSelection(paymentMethods.PAYTM)}
                  defaultValue={selectedPaymentMode}
                  data-testid="payment-modal-input-paytm"
                />
                <span className={styles.imageWrapper}>
                  <Image src={PaytmLogo} alt="" height={15} width={48} />
                </span>
              </label>
            )}
            {paymentMethods.RAZORPAY && (
              <label
                htmlFor="payment-razorpay"
                className={styles.labelContainer}
              >
                <input
                  id="payment-razorpay"
                  className={styles.inputPaymentMethod}
                  type="radio"
                  name="payment"
                  value={paymentMethods.RAZORPAY}
                  onChange={onPaymentOptionSelection(paymentMethods.RAZORPAY)}
                  defaultValue={selectedPaymentMode}
                  data-testid="payment-modal-input-razorpay"
                />
                <span className={styles.imageWrapper}>
                  <RazorpayLogo alt="Razorpay" />
                </span>
              </label>
            )}
          </form>

          <div
            className={styles.submitBtn}
            onClick={onContinue}
            data-testid="payment-modal-button-continue"
          >
            Continue
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
