/* eslint-disable react/no-array-index-key */
import React, { useId } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import Swiper styles
import 'swiper/css';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Slider.module.scss';

function ShopHeaderSliderComponent({ banners }) {
  const id = useId();

  const prevRef = React.useRef();
  const nextRef = React.useRef();

  const [swiper, setSwiper] = React.useState();

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className={styles.wrapper} data-testid="shop-header-slider">
      <Swiper
        id={id}
        modules={[Pagination, Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={15}
        pagination={{
          el: `.${styles.sliderPagination}`,
          clickable: true,
        }}
        autoplay={{
          delay: 5000, // 5 Seconds
        }}
        onSwiper={setSwiper}
      >
        {banners.map((banner, index) => (
          <SwiperSlide
            key={`${index}_${banner?.courseId}`}
            className={styles.sliderSlide}
            data-testid={`shop-slider-slide-${index}`}
          >
            {banner.bannerLink || banner.slug ? (
              <Link
                href={banner.bannerLink || `/course-detail/${banner.slug}`}
                data-testid={`shop-slider-link-banner-${index}`}
              >
                <Image src={banner.image} width={1086} height={160} alt={banner.name} />
              </Link>
            ) : (
              <Image src={banner.image} width={1086} height={160} alt={banner.name} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {banners.length > 1 && (
        <div className={styles.navigationWrapper}>
          <button
            type="button"
            ref={prevRef}
            data-testid="shop-slider-prev"
            className={styles.navPrevSlide}
            aria-label="Prev Slide"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            ref={nextRef}
            data-testid="shop-slider-next"
            className={styles.navNextSlide}
            aria-label="Next Slide"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      )}

      <div className={styles.sliderPagination} data-testid="shop-slider-pagination" />
    </div>
  );
}

export default ShopHeaderSliderComponent;
