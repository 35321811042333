import React, { useId, useState } from 'react';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import styles from './RippleButton.module.scss';

function RippleButton({ onClick, className, isLoading = false, children, ...rest }) {
  const uniqueId = useId();

  const [ripples, setRipples] = useState([]);

  const triggerRipple = (event) => {
    const buttonRect = event.target.getBoundingClientRect();

    const newRipple = {
      x: event.clientX - buttonRect.left,
      y: event.clientY - buttonRect.top,
      size: Math.max(buttonRect.width, buttonRect.height) / 2,
    };

    setRipples([...ripples, newRipple]);
  };

  const handleClick = (event) => {
    triggerRipple(event);

    if (typeof onClick === 'function') onClick(event);
  };

  return (
    <button
      type="button"
      className={classNames(styles.rippleButton, className)}
      onClick={handleClick}
      onMouseEnter={triggerRipple}
      data-testid="ripple-button"
      {...rest}
    >
      {isLoading && (
        <span style={{ marginRight: '10px' }}>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      )}
      {children}
      {ripples.map((ripple, index) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={`ripple-${uniqueId}-${index}`}
          className={classNames(styles.rippleEffect, styles.active)}
          style={{
            top: ripple.y,
            left: ripple.x,
            width: ripple.size,
            height: ripple.size,
          }}
          onAnimationEnd={() => {
            setRipples((prevState) => prevState.slice(1));
          }}
        />
      ))}
    </button>
  );
}

export default RippleButton;
