import classNames from 'classnames';

import styles from './CourseGrid.module.scss';

function StoreCourseGrid({ className, children, ...props }) {
  return (
    <div className={classNames(styles.wrapper, className)} data-testid="course-grid-section-wrapper" {...props}>
      {children}
    </div>
  );
}

export default StoreCourseGrid;
