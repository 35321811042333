import React, { useId } from 'react';

import dynamic from 'next/dynamic';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ModalComponent from '../../Modal';
// Components
import StoreTestimonial from '../../Testimonial';
import styles from './TestimonialSlider.module.scss';

const ShakaPlayer = dynamic(() => import('../../VideoPlayer/Shaka'), { ssr: false });

function StoreTestimonialSlider({ slides }) {
  const sliderId = useId();

  const prevRef = React.useRef();
  const nextRef = React.useRef();

  const [swiper, setSwiper] = React.useState(null);
  const [testimonialVideo, setTestimonialVideo] = React.useState(null);

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const handleVideoPlay = (testimonial) => () => {
    setTestimonialVideo({
      drmProvider: 'ezdrm',
      videoUrl: testimonial.videoUrl,
      referenceId: testimonial.uuid,
      isDrmEnabled: false,
    });
  };

  return (
    <div className={styles.wrapper}>
      <Swiper
        id={sliderId}
        data-testid={`shop-testimonial-${sliderId}`}
        modules={[Navigation, Pagination]}
        slidesPerView={1.2}
        spaceBetween={12}
        pagination={{
          el: `.${styles.sliderPagination}`,
          clickable: true,
        }}
        breakpoints={{
          420: {
            slidesPerView: 'auto',
          },
        }}
        onSwiper={setSwiper}
      >
        {slides.map((testimonial) => (
          <SwiperSlide
            key={testimonial.name}
            className={styles.sliderSlide}
            data-testid={`shop-testimonial-${testimonial.name}`}
          >
            <StoreTestimonial testimonial={testimonial} onViewClick={handleVideoPlay(testimonial)} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={styles.navigationWrapper}>
        <button
          type="button"
          ref={prevRef}
          className={styles.navPrevSlide}
          aria-label="Prev Slide"
          data-testid="prev-slide"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          type="button"
          ref={nextRef}
          className={styles.navNextSlide}
          aria-label="Next Slide"
          data-testid="next-slide"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div className={styles.sliderPagination} />

      {testimonialVideo && (
        <ModalComponent
          className={styles.testimonialPreviewWrapper}
          contentWrapperClass={styles.testimonialVideoWrapper}
          onClose={() => setTestimonialVideo(null)}
          show
        >
          <ShakaPlayer video={testimonialVideo} />
        </ModalComponent>
      )}
    </div>
  );
}

export default StoreTestimonialSlider;
